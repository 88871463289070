import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Copy from 'components/category/copy'
import Content from 'components/category/content'
import AdditionalResources from 'components/new/additional-resources'
import Videos from 'components/category/videos'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const items = [
  {
    link: '/lawn-garden/commercial-mowers/z900-series/',
    imageId: 'z900SeriesImage',
    name: 'Z900 Zero-Turn Mowers',
    alt: 'John Deere Z900 Zero-Turn Mowers',
  },
  {
    link: '/lawn-garden/commercial-mowers/quiktrak-series/',
    imageId: 'quiktrakSeriesImage',
    name: 'QuikTrak Stand-On Mowers',
    alt: 'John Deere QuikTrak Mowers',
  },
  {
    link: '/lawn-garden/commercial-mowers/walk-behind-mowers/',
    imageId: 'walkBehindImage',
    name: 'Walk-Behind Mowers',
    alt: 'John Deere Walk-Behind Mowers',
  },
]

const CommercialMowersPage = ({ data }) => {
  const { allHutsonPromotion, allDeereOffer, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  if (allDeereOffer) {
    allDeereOffer.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Commercial Mowers | Hutson Inc</title>
        <meta
          name='description'
          content='John Deere has brought about some of the best commerical mowers in the game. With great features like the Tweel and electronic deck control, are built for comfort and to last.'
        />
        <meta
          name='keywords'
          content='Commercial Mowers, ZTrak Mowers, Z900, Zero Turn Mowers, Stand On Mowers, QuikTrak Mowers, Walk-Behind Mowers'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Lawn & Garden Equipment',
                'item': 'https://www.hutsoninc.com/lawn-garden/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Commercial Mowers',
                'item': 'https://www.hutsoninc.com/lawn-garden/commercial-mowers/',
              },
            ],
          })}
        </script>
      </Helmet>
      <HeroGradient image={heroImage} heading='Commercial Mowers' />
      <Content>
        <Floater items={gridItems} />
        <Copy header='John Deere Commercial Mowers'>
          <p>
            If you're as serious about mowing as we are about bringing you the best mowers in the
            industry, then I think we can help each other out. Hutson has the full line of
            commercial zero-turn mowers and walk-behind mowers. We bring some of the best features
            and attachments for commercial mowing that the industry has seen. Take the{' '}
            <Link to='/knowledge-center/tweels/'>Tweel</Link> for example... We basically got rid of
            the flat. Or the electronic deck control on the R-Series? So if you're a commercial
            mowing business or just a large property owner, stop by so we can talk shop and show you
            what a commercial mower is supposed to be.
          </p>
        </Copy>
        <Promos data={promos} type='Commercial Mower' />
        <Videos
          type='Commercial Mower'
          id='q0nT_uNTPvM'
          list='PLUz5fj7f_mw8sPwenOkrwNF36TjqDt6Wy'
        />
      </Content>
      <AdditionalResources
        resources={[
          {
            title: 'Shopping Tools',
            links: [
              <OutboundLink href='https://build.hutsoninc.com/commercial-mowers/'>
                Build Your Own
              </OutboundLink>,
              <Link to='/promotions/john-deere/commercial-mowers/'>View Current Promotions</Link>,
            ],
          },
          {
            title: 'Product Brochures',
            links: [
              <OutboundLink href='https://www.deere.com/assets/publications/index.html?id=917cd17c'>
                View Commercial Zero-Turn Mowers Brochure
              </OutboundLink>,
              <OutboundLink href='https://www.deere.com/assets/publications/index.html?id=94dbf5ae'>
                View Commercial QuikTrak&trade; Stand-On Mowers Brochure
              </OutboundLink>,
              <OutboundLink href='https://www.deere.com/assets/publications/index.html?id=b57cd93c'>
                View Commercial Walk-Behind Mowers Brochure
              </OutboundLink>,
            ],
          },
          {
            title: 'Support',
            links: [
              <Link to='/parts/parts-onsite/'>Parts OnSite&trade; Program</Link>,
              <Link to='/government-sales/'>Government Sales</Link>,
            ],
          },
        ]}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query commercialMowersSubcategoryQuery($subcategory: String = "commercial-mowers") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $subcategory }, type: { eq: "john-deere" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    allDeereOffer(filter: { subcategoryList: { in: [$subcategory] } }, limit: 3) {
      nodes {
        ...DeerePromo
      }
    }
    heroImage: file(relativePath: { eq: "headers/commercial-mowers-subcategory-header.jpg" }) {
      ...FullWidthImage
    }
    z900SeriesImage: file(relativePath: { eq: "lawn-and-garden/z900-series.jpg" }) {
      ...FloatingGridImage
    }
    quiktrakSeriesImage: file(relativePath: { eq: "lawn-and-garden/quiktrak-series.jpg" }) {
      ...FloatingGridImage
    }
    walkBehindImage: file(relativePath: { eq: "lawn-and-garden/walk-behind-mowers.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default CommercialMowersPage
